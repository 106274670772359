.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
}

.live {
    background-color: black;
}

body {
    background-color: black;
}

.help {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    text-align: center;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.actions {
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    padding-right: 4rem;
    color: black;
}

.joinButton {
    text-align: center;
}

.tools {
    position: fixed;
    left: 50%;
    bottom: 2rem;
}

@media (orientation: portrait) {
    .tools {
        position: relative;
        margin-left: 50vw;
        left: 0px;
        bottom: auto;
    }
}