.video {
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    display: block;
    object-fit: cover;
}

.container {
    position: absolute;
    left:0px;
    top:0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}