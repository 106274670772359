.container {
    background-color:black;
    color: black;
    padding: 0.5rem;
    box-sizing: border-box;
    border: 1px solid white;
    color: white;
    text-align: center;
}

.container:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

.disabled {
    pointer-events: none;
    opacity: 0.25;
}