.container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    box-sizing: border-box;
    overflow: hidden;
}

.cache {
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.debug {
    padding: 0.5rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    background-color: rgba(0, 0, 0, 0.5)
}

.live {
    position: absolute!important;
    left: 0px;
    top: 0px;
    width: 100%!important;
    height: 100%!important;
    border: none!important;
    margin-top: 0px!important;
}

.thumbnails {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
}

.arrowButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 1rem;
}

.toolContainer {
    position: absolute;
    left: 50%;
    bottom: 2.5rem;
}