.container {
    transition: opacity 0.3s ease-out;
    opacity: 0.75;
}

.container:hover {
    cursor: pointer;
    opacity: 1;
}

.imageleft, .imageright {
    transition: left 0.3s ease-out;
    position: relative;
    left: 0rem;
}

.imageleft:hover {
    left: -0.5rem;
}

.imageright:hover {
    left: 0.5rem;
}


