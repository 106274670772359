.container {
    width: 3rem;
    height: 3rem;
    opacity: 0.25;
}

.active {
    opacity: 1;
}

.container:hover {
    cursor: pointer;
}

.img {
    width: 3rem;
    height: 3rem;
}