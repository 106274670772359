.container {
    font-family: 'ABChanel Corpo Extra';
    font-size: 1rem;
    text-align: center;
    line-height: 2.0rem;
    padding-left: 6rem;
    padding-right: 6rem;
}


.containerDark {
    color: black;
}

.elapsed{
    font-family: 'ABChanel Corpo Semi';
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    position: relative;
    margin-bottom: -0.5rem;
}

.underline {
    display: inline-block;
    width: 8rem;
    height: 1px;
    background-color: white;
}