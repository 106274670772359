.container {
    border: 1px solid #555;
    padding: 1rem;
    display: flex;
}

.input {
    flex: 1;
    margin-left: 1rem;
    background-color: black;
    color: #888;
    border:1px solid #555;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
}

.input:focus {
    outline: none;
}