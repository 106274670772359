.container {
    width: 4rem;
    height: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container:hover {
    cursor: pointer;
}

.icon {
    width: 3rem;
    height: 3rem;
}