.container {
    display: flex;
    border: 1px solid #555;
    padding: 1rem;
    flex-wrap: wrap;
    padding-bottom: 0px;
}

.containerLive {
    border: none;
    position: absolute;
    flex-direction: column;
    padding: 0rem;
    right: 2rem;
    bottom: 13rem;
}


.status {
    margin-bottom: 1rem;
}

.arrowButton {
    width: 15.4rem;
    display: flex;
    justify-content: center;
    margin-bottom: -1rem;
}