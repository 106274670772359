.container {
}

.inner {
   text-align: center;
}

.title {
    box-sizing: border-box;
    font-size: 1.6rem;
    height: 6rem;
    padding-top: 2rem;
}

.trait {
    width: 2rem;
    height: 1px;
    background-color: black;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.photos {
    height: 56rem;
    display: flex;
}

.photosRight {
    width: 42rem;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    position: relative;
}

.photoMini {
    width: 19.4rem;
    height: 27.5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.photoLeft {
    width: 39rem;
    height: 56rem;
}

.slide {
    width: 80rem;
    height: 62rem;
    display: inline-block;
}

.count {
    border-left: 1px solid white;
    border-top: 1px solid white;
    width: 5rem;
    height: 5rem;
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    padding-right: 2px;
    pointer-events: none;
}