.container {
    width: calc(100% + 6rem);
    display: flex;
    flex-direction: column;
    position: relative;
    left:0rem;
    margin-bottom: 4rem;
}

.title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'ABChanel Corpo Semi';
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.tiret {
    width: 2.0rem;
    height: 0.1rem;
    background-color: black;
    display: inline-block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.thumbs {
    overflow-x: scroll;
    padding-left: 3rem;
    white-space: nowrap;
    display: flex;
}

.separatorForced {
    display: block;
    height: 100px;
    width: 22px;
    min-width: 2.22rem;
}