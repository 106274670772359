.container {
    background-color:black;
    min-height: calc(100vh - 9.6rem - 12rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    font-family: 'ABChanel Corpo';
}

.video {
    display: block;
    background-color: #000;
    width: 100%;
    object-fit: cover;
}

.pivotIcon {
    width: 4.6rem;
    margin-bottom: 1rem;
}

.scrollDown {
    pointer-events: none;
    display: flex;
    background-color: rgba(0,0,0, 0.6);
    padding: 2rem;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
}

.downIcon {
    width: 4rem;
    height: 4rem;
}

.instructions {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 16rem;
    margin-top: 10rem; 
    background-color: rgba(0, 0, 0, 0.6);
}

.instructions p {
    padding-left: 5rem;
    padding-right: 5rem;
    line-height: 2rem;
    text-align: center;
    opacity: 1;
}

@media (orientation: landscape) {
    .instructions {
        display: none;
    }
}

@media (orientation: portrait) {
    .container {
        justify-content: flex-start;
    }
    .video {
        margin-top: 4rem;
    }

    .instructions {
        margin-top: 4rem;
    }

    .scrollDown {
        display: none;
    }
}