.container {
}


.video {
    display: block;
    object-fit: cover;
}

.videoContainer {
    opacity: 0;
    position: relative;
}

.videoContainer video {
    width: 240px;
    height: 135px;
    display: block;
    left: 0px;
    top: 0px;
    object-fit: cover;
}