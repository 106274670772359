.container {
    width: 100rem;
    height: 50rem;
    border: 1px solid white;
    backdrop-filter: brightness(80%) blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

@media (max-width: 640px) {
    .container {
        width: 100%;
        height: auto;
        min-height: 40rem;
        margin-left: 2rem;
        margin-right: 2rem;
        flex-direction: column;
    }
}