.container {
    padding: 2rem;
    font-size: 1.2rem;
    line-height: 2rem;
    display: flex;
    background-color: black;
}

.left {
    margin-right: 1rem;
    width: 60rem;
}

.right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.preparing {
    border: 1px solid #555;
    padding: 1rem;
    margin-bottom: 1rem;
}