.container {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    background-color: white;
    color: black;
    padding: 7rem;
    min-width: 79rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    width: 46rem;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.text p {
    margin-bottom: 2rem;
}

.separator {
    width: 2rem;
    height: 0.1rem;
    background-color: black;
    display: inline-block;
    margin-bottom: 1rem;
}

.author {
    margin-bottom: 2rem;
}

.continue {
    padding-left: 6rem!important;
    padding-right: 6rem!important;
}

.logoChanel {
    position: static;
    transform: none;
    display: none;
}

.header {
    display: none;
    border-bottom: 1px solid #D8D8D8;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 9.6rem;
    width: 100%;
    align-items: center;
    justify-content: center;

}


@media (max-width: 640px) {

    .logoChanel {
        display: block;
    }

    .header {
        display: flex;
    }

    .container {
        position: fixed;
        overflow: scroll;
        height: 100vh;
    }

    .text { 
        width: 100%;
    }

    .content {
        min-width: auto;
        padding: 4rem;
        padding-top: 22rem;
        padding-bottom: 16rem;
        display: block;
    }
}