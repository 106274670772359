.container {
    position: fixed;
    color: white;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 200;
}

.arrow {
    display: flex;
    justify-content: center;
    width: 11.5rem!important;
    opacity: 0.6 !important;
    height: 3rem!important;
    align-items: center;
}

.self {
    width: 11.5rem;
    height: 7.4rem;
    border: 1px solid white;
    object-fit: cover;
    display: block;
    margin-top: 1rem;
}

.selfContainer {
    position: relative;
}

.pseudo {
    position: absolute;
    left: 0.5rem;
    bottom: 0.3rem;
}

.masked {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
}

@media (orientation: portrait) {
    .container {
        position: relative;
        width: 100vw;
        flex-direction: row;
        margin-top: -20rem;
        left: 0px;
        overflow-x: scroll;
        padding-left: 2rem;
        padding-right: 1rem;
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .arrow {
        display: none;
    }

    .self { 
        width: 10rem;
        height: 10rem;
        margin-top: 0rem;
        margin-right: 1rem;
    }
}