.container {
    height: 100vh;
    background-image: url(https://remote-sales.imgix.net/chanel/cover_1.png?fm=jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    height: 22.9rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.titleText {
    font-family: "ABChanel Corpo";
    font-size: 4rem;
    letter-spacing: 0.2rem;
    margin-bottom: 2.4rem;
}

.bottom {
    position: absolute;
    bottom: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size:1.1rem;
}

.arrowDown {


}