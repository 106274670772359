.container {
    margin-right: 0.8rem;
}

.footer {
    width: 13.8rem;
}

.footer p {
    width: 13.8rem;
    height: auto;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-top: 0.5rem;
}

.photo {
    width: 13.8rem;
    height: 19.5rem;
    display: block;
}

.numero {
    color: #aaa;
}