.container {
    padding: 0.5rem;  
    font-size: 1.3rem;
    background-color: black;
}

.container section {
    border: 1px solid #555;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.error {
    color: #C44;
}

.container ol {
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.container li {
    display: inline-block;
}

.usersBlock {
    border: 1px solid #555;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
}

.users {
    display: flex;
    margin-top: 0.5rem;
    justify-content: flex-start;
}

.tools {
    margin-top: 0.5rem;
    display: flex;
}

.marginRight {
    margin-right: 0.5rem;
}

