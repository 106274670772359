.container {
    border: 1px solid #555;
    padding: 1rem;
    opacity: 0.4;
    pointer-events: none;
}

.enabled {
    pointer-events: all;
    opacity: 1;
}

.container:hover {
    cursor: pointer;
    background-color: #222;
}