.container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: border 0.5 ease-out;
}

.container span {
    opacity: 0.25;
}

span.selected {
    opacity: 1;
}

.container:hover {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2); 
}