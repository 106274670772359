.container {
    border: 1px solid #555;
    padding: 1rem;
    margin-bottom: 1rem;
}

.actions {
    display: flex;
}

.spacer {
    margin-right: 1rem;
}