.container {
    border: 1px solid #555;
    margin-bottom: 0.5rem;
    padding: 1rem;
    margin-right: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 32rem;
}

.line {
    border: 1px solid #555;
    margin-top: 1rem;
    padding: 1rem;
}

.stepDown {
    margin-bottom: 1rem;
}
