.container {
    position: fixed;
    left: 0px; 
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: black;
    color: white;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close {
    position: absolute;
    left: 2rem;
    top: 2rem;
}

.image {
    width: 100vw;
}

.buttons {
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100vw;
    display: flex;
}

.leftArrow {
    margin-right: auto;
}

.rightArrow {

}