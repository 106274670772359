.container {
    position: relative;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border: 1px solid #555;
    width: 240px;
    height: 135px;
}

.containerLive {
    border: 1px solid white;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 1rem;
    width: 15.4rem;
    height: 10rem;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

.containerLive video {
    width: 15.4rem!important;
    height: 10rem!important;
    object-fit: cover;
    box-sizing: border-box;
}

.containerLive .label {
    font-size: 1.1rem;   
    left: 0.5rem;
    bottom: 0.1rem;
}

.containerLive .legend {
    display: none;
}

.label {
    position: absolute;
    left: 0.5rem;
    bottom: 0.1rem;
}

.legend {
    color: #aaa;
}