.container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black;
}

.image {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.front {
    left: 0px;
    top: 0px;
    z-index: 2;
}

.back {
    left: 0px;
    top: 0px;
    z-index: 1;
}

.debug {
    color: white;
    z-index: 3; 
    position: absolute;
    left: 0px; 
    top: 0px;
}