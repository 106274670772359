.container {
    position: absolute;
    left:0px;
    top:0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: black;
}

.toolbarContainer {
    position: absolute;
    bottom: -8rem;
    left: 50%;
}

.cache {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
}
