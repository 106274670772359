.container {
    position: absolute;
    right: 13rem;
    top: 7rem;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    width: 5.2rem;
}

.labelInitial {
    display: block;
    min-width: 3rem;
    text-align: left;
}

.container:hover{
    cursor: pointer;
}

.current {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3rem;
    height: 2rem;
}

.arrow {
    width: 3rem;
    height: 3rem;
    margin-left: 1rem;
}

.arrow img {
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    left: -1.7rem;
    top: -0.7rem;
}
.separator {
    height: 1rem;
}

.body {
    width: 5.2rem;
    border-top: 1px solid white;
    box-sizing: border-box;
    height: -1px;
    text-align: center;
    overflow-y: hidden;
    height: 0rem;
    transition: height 0.2s ease-out;
}

.plang {
    color: rgba(255,255, 255, 0.7);
    margin-bottom: 0.5rem;
}

.plang:hover {
    color: white;
}

.open {
    height: 11.5rem;
}