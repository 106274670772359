.container {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left:0px;
    top: 0px;
    z-index: 150;
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events:visibleFill;
    align-items: center;
    justify-content: flex-start;
    color: black;
}

.content {
    background-color: white;
    width: 44rem;
    padding: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    z-index: 10;
    margin-left: 6rem;
}

.settingsMedia {
    margin-bottom: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settingsLabel {
    color: black;
    font-size: 1.1rem;
    margin-bottom: 2rem;

}

.iconContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.previewFooter {
    display: flex;
    position: relative;
    font-family: Helvetica, sans-serif;
    font-size: 1.1rem;
    color: white;
    height: 3.3rem;
    background-color: black;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.pseudo {
    font-family: 'ABChanel Corpo';
    font-size: 1.0rem;
    color: white;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}

.videoContainer {
    width: 39rem;
    height: 22rem;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 2.5rem;
    background-color: #ececec;
}

.stepLabel {
    font-family: "ABChanel Corpo Semi";
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

.videoInfos {
    position: absolute;
    color: white;
    bottom: 0px;
    height: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1d1d1d;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
}

.instructions {
    font-family: Helvetica, sans-serif;
    font-size: 1.5rem;
    line-height: 2.4rem;
    color: #767676;
    margin-bottom: 3rem;
}

.video {
    width: 39rem;
    height: 22rem;
    background-color: #333333;
    position: absolute;
    left: 0px;
    top: 0px;
    object-fit: cover;
}

.helpLink {
    color: black;
    font-weight: bold;
    margin-bottom: 6rem;
}

.disableMedias {
    pointer-events: none;
    opacity: 0.25;
}