body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@font-face {
  font-family: 'ABChanel Corpo';
  src:  url('./fonts/ABChanelCorpo-Regular.ttf') format('truetype'),
        url('./fonts/ABChanelCorpo-Regular.woff') format('woff'),
        url('./fonts/ABChanelCorpo-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABChanel Corpo Semi';
  src:  url('./fonts/ABChanelCorpo-SemiBold.ttf') format('truetype'),
        url('./fonts/ABChanelCorpo-SemiBold.woff') format('woff'),
        url('./fonts/ABChanelCorpo-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABChanel Corpo';
  src:  url('./fonts/ABChanelCorpo-Light.ttf') format('truetype'),
        url('./fonts/ABChanelCorpo-Light.woff') format('woff'),
        url('./fonts/ABChanelCorpo-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABChanel Corpo Extra';
  src:  url('./fonts/ABChanelCorpo-ExtraLight.ttf') format('truetype'),
        url('./fonts/ABChanelCorpo-ExtraLight.woff') format('woff'),
        url('./fonts/ABChanelCorpo-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

html {
  margin: 0;
  border: 0;
  font-size: 62.5%;
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



p, h1, h2, h3, h4, h5 {
  border: 0;
  margin: 0;
  padding: 0;
}

