.container {
    border: 1px solid #555;
    margin-bottom: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    font-size: 2rem;
}

.icon {
    width: 4rem;
    height: 4rem;
    margin-right: 0.5rem;
}

.infos {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.version {
    padding: 0px;
    margin: 0px;
    margin-left: auto;
}

.warning {
    background-color: #555;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1.5rem;
    align-self: flex-start;
}