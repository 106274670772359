.container {
    border: 1px solid #555;
    padding: 1rem;
    margin-bottom: 1rem;
}

.containerLive {
    border: none;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.containerLive .topContainer {
    width: 100%;
    height: 100vh;
}


.containerLive .videoContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100%;
}

.containerLive .videoContainer video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
}

.videoContainer {
    opacity: 0;
    position: relative;
    width: 72rem;
}

.topContainer {
    position: relative;
}

.videoContainer video {
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
}

.videoLabel {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.retry {
    margin-left: auto;
}

.red5logo {
    width: 4rem;
    height: 4rem;
    margin-right: 0.5rem;
}

.dot {
    background-color:#555;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    margin-right: 1rem;
    border-radius: 0.5rem;
}

.dotActive {
    background-color:red;
}

.closeContainer {
    position: absolute;
    right: 2rem;
    top: 2rem;

}

.closeIcon {
    width: 2rem;
    height: 2rem;
    opacity: 0.3;
}

.closeIcon:hover {
    cursor: pointer;
    opacity: 1;
}

.toolbarContainer {
    position: absolute;
    left: 50%;
    bottom: -8rem;
}