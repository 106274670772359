.container {
    width: 100%;
    margin-bottom: 8rem;
}

.arrow {
    width: 4rem;
    height: 4rem;
}

.caroussel {
    position: relative;
    width: 200rem;
    left: 50%;
    transform: translateX(-50%);
}

.arrowLeft {
    position: absolute;
    left: 50%;
    z-index: 10;
    margin-left: -52rem;
    margin-top: 0.8rem;
}

.arrowRight {
    position: absolute;
    left: 50%;
    z-index: 10;
    margin-left: 48rem;
    margin-top: 0.8rem;
}

.arrow:hover {
    cursor: pointer;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
}

.paginationItem:hover {
    cursor: pointer;
}

.paginationItem {
    width: 2rem;
    text-align: center;
    font-size: 1.2rem;
    color: #aaa;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.paginationItemSelected {
    color: black;
    border-bottom: 1px solid black;
}

.arrowOff {
    opacity: 0.0;
}