.container {
}

.select {
    font-family: 'ABChanel Corpo';
    font-size: 1.1rem!important;
    color: black;
    border: none;
    width: 37rem;
    padding: 1rem;
    border-bottom: 1px solid #ececec;
    
}

.select:focus {
    outline: none;
}

.select option {
    background: red;
    font-size: 1.0rem;
}