.container {
    position: absolute;
    left: 1rem;
    bottom: 4rem;
    display: flex;   
    align-items: center;
}

.separator {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.logo {
    width: 3rem;
    height: 3rem;
}