.transitionContainer {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0;
}

.logoChanel {
    top: 36vh;
    margin-top: -2rem;
    opacity: 0;
    position: absolute;
    left: 50%;
}