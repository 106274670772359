.container {
    font-family: 'ABChanel Corpo';
    color: 'white';
    background-color: black;
    font-size: 1.1rem;
    padding: 1.8rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    box-sizing: border-box;
    color: white;
}

.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.container:hover {
    cursor: pointer;
}

.container:active {
    background-color:#333;
}

