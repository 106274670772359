.container {
   position: fixed;
   left: 0px;
   top: 0px;
   width: 100%;
   z-index: 140;
}

.content {
    left: 0px;
    top: 0px;
    position: relative;
    width: 100%;
    height: 6rem;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.2rem;
    box-sizing: border-box;
}