.container {
    background-color: yellow;
    position: relative;
    box-sizing: border-box;
    position: relative;
}


.imgPhoto {
    position: absolute;
    display: block;
}

.overlay {
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    font-size: 1.0rem;
}

.overlay:hover {
    cursor: pointer;
    opacity: 1;
}

.expandIcon {
    width: 3rem;
    margin-bottom: 1rem;
}