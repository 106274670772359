.container {
    position: relative;
}

.videoContainer {
    margin-top: 1rem;
    position: relative!important;
    display: block;
    width: auto;
    height: auto;
    opacity: 1;
}

.masked {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    width: 0px;
    height: 0px;
}

.videoContainer video {
    width: 11.5rem;
    height: 7.4rem;
    border: 1px solid white;
    object-fit: cover;
    display: block;
}

.pseudo {
    position: absolute;
    left: 0.5rem;
    bottom: 0.3rem;
}

@media (orientation: portrait) {

    .videoContainer video {
        width: 10rem;
        height: 10rem;
    }


    .videoContainer {
        margin-top: 0rem;
        margin-right: 1rem;
        height: 12rem;
    }


}
