.container {
    width: 100%;
    height: calc(100vh - 12rem);
    background-color: black;
}

.cadre {
    width: 100vw;
    height: calc(100vh - 12rem);
    position: relative;
}

.slideContainer {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 12rem);
}

.rightArrow {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 30;
}

.leftArrow {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translate(0%, -50%);
    z-index: 30;
}

.paginator {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.dot {
    width: 3rem;
    height: 0.1rem;
    background-color: black;
    display: inline-block;
    margin: 0.2rem;
    opacity: 0.25;
    transition: opacity 0.5s ease-out;
    cursor: pointer;
    border-top: 8px solid white;
    border-bottom: 8px solid white;
}

.dotSelected {
    opacity: 1;
}

@media (max-width: 640px) {
    .cadre {
        width: 100vw;
        height: 21rem;   
    }

    .leftArrow {
        left: -1rem;
        padding: 2rem;
    }

    .leftArrow img {
        width: 2rem;
        height: 2rem;
    }

    .rightArrow {
        right: -1rem;
        padding: 2rem;
    }

    .rightArrow img {
        width: 2rem;
        height: 2rem;
    }
}