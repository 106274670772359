.container {
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12rem;
}

.title {
    display: flex;
    height: 22.9rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.titleText {
    font-family: "ABChanel Corpo";
    font-size: 4rem;
    letter-spacing: 0.2rem;
    margin-bottom: 2.4rem;
}


.logo {
    transform: none;
    position: relative;
    left:auto;
    top:auto;
}

.caroussel {
    position: relative;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    z-index: 100;
}

.gouaches {
    background-color: white;
    display: flex;
    color: black;
    flex-direction: column;
    align-items: center;
}

.gouachesTitle {
    font-family: 'ABChanel Corpo';
    font-size: 2.5rem;
    font-weight: normal;
}

.gouachesHeader {
    display: flex;
    height: 10rem;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    margin-top: 4.8rem;
    margin-bottom: 1.5rem;   
    text-align: center;
    line-height: 2.3rem;
}

.separatorLine {
    display: inline-block;
    background-color: #d8d8d8;
    width: 120rem;
    height: 0.1rem;
}

.disclaimer {
    width: 90rem;
    text-align: center;
    font-family: Helvetica, sans-serif;
    font-size: 1.2rem;
    color: #767676;
    line-height: 2.0rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.footer {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.4rem;
    border-top: 1px solid #ddd;
    padding-bottom: 10rem;
}

.cache {
    position: fixed;
    left: 0px;
    top: 0px;
    background-color:black;
    width: 100%;
    height: 100%;
    z-index: 150;
}

.lineMobile {
    display: none;
}

@media (max-width: 640px) {
    .header {
        border-bottom: 1px solid #333333;
        padding-bottom: 2rem;
        box-sizing: border-box;
    }

    .titleText { 
        font-size: 2.0rem;
    }

    .title { 
        height: 18.6rem;
    }

    .caroussel {
        height: 21rem;
        margin-bottom: 9.7rem;
    }

    .footer { 
        background-color: white;
        display: flex;
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
        justify-content: center;
        padding-bottom: 6rem;
    }

    .gouaches {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .disclaimer {
        width: 100%;
    }

    .lineMobile {
        display: block;
        background-color: #000000;
        width: 100%;
        height: 2px;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .logo {
        display: block;
        position: static;
        margin-top: 3rem;
    }

    .separatorLine {
        display: none;
    }

    .gouachesHeader {
        font-size: 1.4rem;
    }
}