.container {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 12rem);
}

.preview {
    width: 100vw;
    height: calc(100vh - 12rem);
    background-size: cover;
    background-position: center;
}

.playButton {
    margin-left: -1rem;
}

.videoContainer {
    pointer-events: none;
}

.videoContainerPlaying {
    pointer-events: all;
}


.video {
    position: absolute;   
    left: 0px;
    top: 0px;
    width: 100vw;
    height: calc(100vh - 12rem);
    object-fit: cover;
}

.video:focus {
    outline: none;
}

.pad {
    position: absolute;
    right: 0px; 
    top: 0px;
    height: calc(100vh - 12rem);
    width: 30vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.padIntro {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.padTitle {
    font-size: 4rem;
    line-height: 4.5rem;
    margin-bottom: 1rem;
}

@media (max-width: 640px) {
    .container {
        width: 100vw;
        height: 21rem;   
    }

    .preview {
        width: 100vw;
        height: 21rem;   
    }

    .video {
        width: 100vw;
        height: 21rem;   
    }

    .playButton { 
        left: 1rem;
        bottom: 1rem;
        width: 5rem!important;
        height: 5rem!important;
    }

}