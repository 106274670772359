.container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.inputDigit {
    font-family: 'ABChanel Corpo';
    font-size: 1.4rem;
    width: 4.0rem;
    height: 4.7rem;
    margin: 0.5rem;
    text-align: center;
    color: black;
}

.inputDigit:focus {
    outline: none;
}

@media (max-width: 640px) {
    .inputDigit {
        font-size: 16px;
    }
}
