.link {
    text-decoration: underline;
    font-family: "Helvetica";
    font-size: 1.2rem;
    padding: 1rem;
    color:#ececec;
    opacity: 0.7;
}

.link:hover {
    cursor: pointer;
}