.container {
    background-color: rgba(0,0,0,0.85);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    padding: 3rem;
    box-sizing: border-box;
}

.content {
    background: white;
    width: 100%;
    color: black;
    padding: 3rem;
    position: relative;
    top: -5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.thumbnail {
    width: 8rem;
    height: 8rem;
    border-radius: 4rem;
    border: 2px solid black;
}

.placeTitle {
    font-size: 1rem;
    color: #767676; 
    margin-top: 1rem;
}

.instructions {
    font-size: 1.1rem;
    color: black;
    text-align: center;
    margin-top: 2rem;
    width: 25rem;
}

.actions {
    margin-top: 2rem;
}