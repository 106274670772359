.container {
    position: relative;
    width: 15.4rem;
    height: 10rem;
    border: 1px solid white;
    margin-top: 1rem;
}

.hidden {
    position: absolute;
    height: 0px;
    left: 30rem;
}

.video {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.micIcon {
    width: 4rem;
    height: 4rem;
}

.onlyMic {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
}

.bandwidth {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 1rem;
}

.name {
    position: absolute;
    font-family: 'ABChanel Corpo';
    font-size: 1.0rem;
    color: white;
    position: absolute;
    left: 0.8rem;
    bottom: 0.8rem;
    box-sizing: border-box;  
}

.legend {
    position: absolute;
    left: 1rem;
    top: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 1rem;
}

.controls {
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;
    display: flex;

}