.container {
    display: flex;
    position: relative;
    flex-direction: column;
}

.video {
    background-color: #333;
    height: 21rem;
    width: 100%;
    object-fit: cover;
}

.infos {
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 4.2rem;
    background-color: #1D1D1D;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
}