.container img {
    width: 3rem;
    height: 3rem;
    animation: img 0.8s linear infinite;
}

@keyframes img {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  
.container {
    width: 3rem;
    height: 3rem;
}