.container {
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxed {
    display: flex;
    font-family: 'ABChanel Corpo';
    font-size: 1.2rem;
    line-height: 2.4rem;
    width: 30rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.separator {
    width: 2.4rem;
    height: 1px;
    background-color: white;
    margin-top: 2.5rem;
}

.blackMask {
    opacity: 0;
    width: 10rem;
    height: 10rem;
    position: absolute;
    left: 0px;
    top: 0px;
    background-color: black;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.blackMaskActive {
    opacity: 1;
}

@media (max-width: 640px) {
    .container {
        min-height: 85vh;
        background-position: top center;
    }
    .centered {
        padding-bottom: 4rem;
        padding-top: 4rem;
    }

    
}

.logoMobile {
    position: static;
    transform: none;
    margin-bottom: 4rem;
}
