.container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 120;
}

.videoContainer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.video {
    position: absolute;
    left: 0px;
    top:0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.debug {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
}

.titleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-family: "";
    font-family: 'ABChanel Corpo Extra';
    font-size: 6rem;
    justify-content: center;
    text-align: center;
    opacity: 0;
    user-select: none;
}

.logoChanel {
    top: 0px;
    position: relative;
    margin-bottom: 6rem;
}