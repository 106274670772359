.container {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    z-index: 10;
}

.video {
    width: 15.4rem;
    height: 10rem;
    object-fit: cover;
    display: block;
    border: 1px solid white;
    box-sizing: border-box;
}

.name {
    position: absolute;
    left: 0.5rem;
    bottom: 0.2rem;
    font-size: 1.0rem;
    user-select: none;
}

.camoff {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
}

.micoff {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
}