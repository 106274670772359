.container {
    height: 9.6rem;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 11.1rem;
}