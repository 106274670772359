.container {
    margin-bottom: 1rem;
    transition: height 0.5s ease-out, opacity 0.5s ease-out;
    transition-delay: 0s, 1s;
    position: relative;
    opacity: 1;
    height: 10rem;
}

.video {
    width: 15.4rem;
    height: 10rem;
}

.content {
    position: relative;
}

.hidden {
    position: absolute;
    left:0px;
    top:0px;
    pointer-events: none;
    opacity: 0;
    height: 0rem;
}

.video video {
    width: 15.4rem;
    height: 10rem;
    object-fit: cover;
    display: block;
    border: 1px solid white;
    box-sizing: border-box;
}

.camoff {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
}

.micoff {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
}

.name {
    position: absolute;
    left: 0.5rem;
    bottom: 0.2rem;
    font-size: 1.0rem;
    user-select: none;
}