.container {
    border: 1px solid #555;
    padding: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.video {
    display: block;
    border: 1px solid #555;
    object-fit: cover;
    margin-bottom: 1rem;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.videoContainer {
    position: relative;
}

.videoLive .videoContainer {
    position: relative;
}

.videoContainerLive {
    z-index: 10;
    right: 2rem;
    bottom: 2rem;
    position: absolute;
}

.videoLive {
    border: 1px solid white;
    z-index: 10;
    width: 15.4rem;
    height: 10rem;
    display: block;
    margin-bottom: 0rem;
    box-sizing: border-box;
}

.label {
    position: absolute;
    bottom: 0.3rem;
    left: 0.5rem;
    font-size: 1.1rem;   
}