.container {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    left:0px;
    top: 0px;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events:visibleFill;
    color: white;
    align-items: center;
    justify-content: center;
}

.cadrePhoto {
    position: relative;
    border: 1px solid white;
    overflow: hidden;
}

.imgZoom {
    opacity: 1.0;
}

.imgZoomed {
    cursor: zoom-out !important;
}

.imgZoom:hover {
    cursor: zoom-in;
}

.throbber {
    position: absolute;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
}

.motionContent {
    position: relative;
}

.closeButton {
    position: absolute;
    right: -3rem;
    top: -3rem;
}

.arrowRight {
    position: absolute;
    right: -24rem;
    top: 50%;
    transform: translate(0%, -50%);
}

.arrowLeft {
    position: absolute;
    left: -24rem;
    top: 50%;
    transform: translate(0%, -50%);
}

.legend {
    font-family: 'ABChanel Corpo';
    font-size: 1.0rem;
    color: white;
    position: absolute;
    right: 1.2rem;
    bottom: 1.2rem;
    z-index: 100;
}

.countLabel {
    font-family: 'ABChanel Corpo';
    font-size: 1.0rem;
    color: white;
    position: absolute;
    bottom: -3rem;
    left: 50%;
    transform: translate(-50%, 0%);
}