.container {
    display: flex;
}

.input {
    background-color: #111;
    border: 1px solid #555;
    color: white;
    padding: 0.5rem;
    flex: 1;
}

.input:focus {
    outline: none;
}

.ok {
    margin-left: 1rem;
}